import styled from "styled-components";

const Container = styled("div")`
    width: 100vw;
    height: 100px;
    background-color; #00FF00;
    `
const Title = styled("h1")`
    Margin: 0;
`

const Navbar = () => {
    return(
        <Container>
            <Title>
                Penguin Labs
            </Title>
        </Container>
    )
}

export default Navbar;